import React from "react";
import styled from "styled-components";

const Card = styled.div`
  max-width: 581px;
  width:40%;
  height: 686px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: var(--orange);

  .white_line_divider{
    width: 95%;
    height: 7px;
    border-top: 5px solid #ffffff;
  }

  @media only screen and (max-width: 768px){
    width: auto;
    max-width: 454px;
    height: 605px;
  }

  @media only screen and (max-width: 500px){
    max-width: 280px;
    height: 371px;
  }
`;

const UpperTextField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  .fb_rev_date{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px !important;
    line-height: 130.4%;
    letter-spacing: -0.005em;
    text-align: left;
    color: #ffffff;
    width: 100%;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 1250px){
      font-size: 25px !important;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px !important;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px !important;
      text-align: center;
    }

    @media only screen and (max-width: 500px){
      font-size: 20px !important;
    }
  }

  .revenue_amount__container{
    background-color: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .revenue_amount__value{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 48px !important;
    line-height: 130.4%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    margin: 0;

    @media only screen and (max-width: 1250px){
      font-size: 40px !important;
    }

    @media only screen and (max-width: 1024px){
      font-size: 32px !important;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px !important;
    }

    @media only screen and (max-width: 500px){
      font-size: 25px !important;
    }
  }
`;

const BottomTextField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .roas_text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px !important;
    line-height: 130.4%;
    letter-spacing: -0.005em;
    text-align: left;
    color: #ffffff;
    width: 100%;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 1250px){
      font-size: 25px !important;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px !important;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px !important;
      text-align: center;
    }

    @media only screen and (max-width: 500px){
      font-size: 20px !important;
    }
  }

  .roas_amount__container{
    background-color: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .roas_amount__value{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 48px !important;
    line-height: 130.4%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    margin: 0;

    @media only screen and (max-width: 1250px){
      font-size: 40px !important;
    }

    @media only screen and (max-width: 1024px){
      font-size: 32px !important;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px !important;
    }

    @media only screen and (max-width: 500px){
      font-size: 25px !important;
    }
  }
`;

const BelowDividerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  padding: 0 3rem;
`;

const AboveDividerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  padding-left: 3rem;
  position: relative;

  .card_title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 40px !important;
    line-height: 130.4%;
    letter-spacing: -0.005em;
    color: #ffffff;
    text-align: left;
    width: 100%;

    @media only screen and (max-width: 1250px){
      font-size: 33px !important;
    }

    @media only screen and (max-width: 1024px){
      font-size: 26px !important;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px !important;
    }

    @media only screen and (max-width: 500px){
      font-size: 20px !important;
    }
  }

  .card_title_bold{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 40px !important;
    line-height: 130.4%;
    letter-spacing: -0.005em;
    color: #ffffff;
    text-align: left;
    width: 100%;

    @media only screen and (max-width: 1250px){
      font-size: 33px !important;
    }
    @media only screen and (max-width: 1024px){
      font-size: 26px !important;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px !important;
    }

    @media only screen and (max-width: 500px){
      font-size: 20px !important;
    }
  }

  .white_vertical_line{
    position: absolute;
    height: 75%;
    width: 1%;
    background-color: #ffffff;
    top: 10%;
    left: 5%;
  }
`;

const RevCard = ({ sDate, eDate, revenue, roas, beforeAfter }) => {
  return (
    <Card>
      <AboveDividerContainer>
        <h6 className='card_title'>ROAS & FB Revenue</h6>
        <h6 className='card_title_bold'>{beforeAfter} FFG</h6>
        <div className="white_vertical_line"></div>
      </AboveDividerContainer>
      <div className="white_line_divider"></div>
      <BelowDividerContainer>
        <UpperTextField>
          <p className='fb_rev_date'>FB Revenue:&nbsp;{sDate} - {eDate}</p>
          <div className="revenue_amount__container">
            <p className='revenue_amount__value'>{revenue}</p>
          </div>
        </UpperTextField>
        <BottomTextField>
          <p className='roas_text'>ROAS:</p>
          <div className="roas_amount__container">
            <p className='roas_amount__value'>{roas}</p>
          </div>
        </BottomTextField>
      </BelowDividerContainer>
    </Card>
  );
};

export default RevCard;