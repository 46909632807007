import React from "react";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { Link } from "gatsby";

const TextWrapper = styled.div`
  max-width: 1440px;
  margin-bottom: 3rem;
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  // @media only screen and (min-width: 1920px){
  //   position: relative;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   /* display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: column; */
  // }

  @media only screen and (max-width: 768px){
    margin-top: 4rem;
  }

  @media only screen and (max-width: 500px){
    margin-top: 2rem;
  }
`;

const RichTextContainer = styled.div`
  padding:0 3rem;
  max-width: 1440px;
  width: 100%;

  .rich_paragraph{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    color: #000000;
    text-align: center;
    margin-bottom: 3rem;
    padding: 0 1.4rem;

    /* @media only screen and (max-width: 1250px){
      font-size: 22px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 20px;
    } */

    @media only screen and (min-width: 768px){
      text-align: left;
      padding: 0;
    } 
  }

  .rich_h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    text-align:${({ textAlignment }) => `${textAlignment}`};
    margin-bottom: 3rem;

    @media only screen and (min-width: 640px){
      font-size: 24px;
    }

    @media only screen and (min-width: 768px){
      font-size: 36px;
    }
  }

  .rich_h3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130.4%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    background-color: var(--blue);
    padding:0 1rem;
    width: 20%;
    margin-bottom: 3rem;

    @media only screen and (max-width: 1250px){
      font-size: 35px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 32px;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 25%;
      
      padding: 0.5rem 1rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 25px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 45%;
      margin-bottom: 1rem;
      padding: 0.5rem 1rem;
    }
  }

  .rich_h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 105.4%;
    letter-spacing: -0.005em;
    text-align: center;
    color: var(--blue);
    margin-bottom: 1rem;

    @media only screen and (min-width: 640px){
      font-size: 24px;
    }

    @media only screen and (min-width: 768px){
      font-size: 36px;
      text-align: left;
    }

    /* @media only screen and (max-width: 768px){
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 152.9%;
      text-align: center;
      letter-spacing: -0.005em;
      padding: 0 8.3rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 25px;
      padding: 0;
    } */
  }

  .rich_h5{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 105.4%;
    letter-spacing: -0.005em;
    text-align: ${({ textAlignment }) => `${textAlignment}`};
    color: var(--blue);
    margin-bottom: 3rem;

    @media only screen and (max-width: 1250px){
      font-size: 28px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (max-width: 768px){
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 152.9%;
      text-align: center;
      letter-spacing: -0.005em;
      padding: 0 6.2rem;
      margin-bottom: 0rem;
    }

    @media only screen and (max-width: 500px){
      padding: 0;
    }
  }

  .rich_ul{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    text-align: left;
    list-style-type: revert;

    @media only screen and (max-width: 768px){
      & > li > p{
        text-align:left;
      }
    }
    
    @media only screen and (max-width: 500px){
      /* text-align: center; */

      & > li::marker{
        font-size:12px;
      }
    }
  }

  .rich_ol{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    list-style-type: auto;

    @media only screen and (max-width: 1250px){
      font-size: 22px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 20px;
    }
    
    @media only screen and (max-width: 768px){
      text-align: center;
      list-style-position: inside;
      padding: 0;
      font-size: 20px;

      & > li {
        margin-bottom: 3rem;
      }

      & > li > .rich_paragraph {
        margin-bottom: 0;
        font-size: 20px;
        padding: 0 7.7rem;
      }

      & > li > .rich_ul{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      & > li > .rich_ul > li > p{
        margin-bottom: 0;
        text-align: left;
        font-size: 20px;
      }
    }

    @media only screen and (max-width: 500px){
      font-size: 18px;

      & > li > .rich_paragraph {
        font-size: 18px;
        padding: 0;
      }

      & > li > .rich_ul > li > p{
        font-size: 18px;
      }

    }
  }

  .linebreak--mobile{
    @media only screen and (min-width: 769px){
      display: none;
    }
  }

  .rich_ol strong{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 152.9%;
    /* or 46px */
    color: var(--blue);
    letter-spacing: -0.005em;
    list-style-type: auto;

    @media only screen and (max-width: 1250px){
      font-size: 22px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 20px;
    }

    @media only screen and (max-width: 768px){
      font-size: 20px;
    }

    @media only screen and (max-width: 500px){
      font-size: 18px;
    }
  }

  .bubble_container{
    margin-top: 3rem;
    margin-bottom: 5rem;

    @media only screen and (max-width: 500px){
      margin-bottom: 3rem;
    }
  }

  .bubble {
    min-width: 200px;
    background-color: var(--blue);
    max-width: 650px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 2rem;
    border-radius: 1rem;

    @media only screen and (max-width: 768px){
      max-width: 380px;
    }

    @media only screen and (max-width: 500px){
      padding: 1rem;
      border-radius: 0.5rem;
    }
  }

  .pointer {
    height: 40px;
    width: 40px;
    background-color: var(--blue);
    margin: 0 auto;
    transform: rotate(45deg);
    margin-top: -22px;
    position: relative;
    left: calc(0.5vw - 13rem);
    z-index: -1;

    @media only screen and (max-width: 768px){
      height: 30px;
      width: 30px;
      left: calc(0.5vw - 7rem);
    }

    @media only screen and (max-width: 500px){
      left: calc(0.5vw - 5rem);
    }
  }

  .bubble h6{
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    font-size: 28px;
    line-height: 142.4%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    margin: 0;

    @media only screen and (max-width: 1250px){
      font-size: 24px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 20px;
    }

    @media only screen and (max-width: 768px){
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 500px){
    padding: 0 12px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;

  .lets_chat_button{
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    margin-bottom: 5rem;
    
    @media only screen and (min-width: 1025px){
      max-width: 316px;
      width: 100%
    }
  }
`;

const RICHTEXT_OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: text => <strong>{text}<br className='linebreak--mobile'></br></strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className='rich_paragraph'>{children}</p>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className='rich_h2'>{children}</h2>;
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <h3 className='rich_h3'>{children}</h3>
      );
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <h4 className='rich_h4'>{children}</h4>;
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return <h5 className='rich_h5'>{children}</h5>;
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return (
        <div className='bubble_container'>
          <div className="bubble">
            <h6>{children}</h6>
          </div>
          <div className="pointer"></div>
        </div>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className='rich_ul'>{children}</ul>;
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className='rich_ol'>{children}</ol>;
    },
  },
};

const Text = ({ data }) => {
  const { richText, textAlignment, isLastComponent } = data;
  const json_text = JSON.parse(richText.raw);
  return (
    <TextWrapper>
      <RichTextContainer textAlignment={textAlignment}>
        {documentToReactComponents(json_text, RICHTEXT_OPTIONS)}
      </RichTextContainer>
      {isLastComponent && <ButtonContainer>
        <Link to="/contact-us"><button className='hire_button lets_chat_button'>Let&apos;s Chat</button></Link>
      </ButtonContainer>}
    </TextWrapper>
  );
};

export default Text;