import React from "react";
import styled from "styled-components";
import RevCard from "./card";

const RevCardsWrapper = styled.div`
  max-width: 1440px;
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  // @media only screen and (min-width: 1920px){
  //   position: relative;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1440px;
  width: 100%;

  @media only screen and (max-width: 768px){
    flex-direction: column;
    row-gap: 3rem;
  }
`;

const BeforeAfterRevenueCard = ({ data }) => {
  const {
    beforeFbRevenueStartDate,
    beforeFbRevenueEndDate,
    beforeRevenueAmount,
    beforeRoas,
    afterFbRevenueStartDate,
    afterFbRevenueEndDate,
    afterRevenueAmount,
    afterRoas,
  } = data;

  return (
    <RevCardsWrapper>
      <CardsContainer>
        <RevCard
          beforeAfter={"Before"}
          eDate={beforeFbRevenueEndDate}
          revenue={beforeRevenueAmount}
          roas={beforeRoas}
          sDate={beforeFbRevenueStartDate}
        />
        <RevCard
          beforeAfter={"After"}
          eDate={afterFbRevenueEndDate}
          revenue={afterRevenueAmount}
          roas={afterRoas}
          sDate={afterFbRevenueStartDate}
        />
      </CardsContainer> 
    </RevCardsWrapper>
  );
};

export default BeforeAfterRevenueCard;