import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";

import { Banner, BeforeAfterRevenueCard, Hero, Images, LevelCard, RevenueTable, Text } from "../components/case-study";
import SEO from "../components/seo";

const sections = {
  Banner: Banner,
  BeforeAfterRevenueCard: BeforeAfterRevenueCard,
  Hero: Hero,
  Images: Images,
  LevelCard: LevelCard,
  RevenueTable: RevenueTable,
  Text: Text,
};

const TemplateCaseStudy = (pageContext) => {
  
  const page_data = pageContext.data;
  const page_references = page_data.contentfulCaseStudiesPageCards.caseReference;
  const { seoTitle, seoDescription } = page_data.contentfulCaseStudiesPageCards;

  return (
    <Layout>
      <SEO
        description={seoDescription}
        title={seoTitle}
      />
      {page_references.map((ref, index) => {
        const Section = sections[ref.internal.type.replace("ContentfulCaseStudy", "")];
        return (
          <Section
            data={ref}
            key={index}
          />
        );
      })}
    </Layout>
  );
  
};

export default TemplateCaseStudy;

export const query = graphql`
query caseStudyQuery($name: String) {
  contentfulCaseStudiesPageCards(caseName: {eq: $name}) {
    caseName
    seoTitle
    seoDescription
    caseReference {
      ... on ContentfulCaseStudyBanner {
        internal {
          type
        }
        orangeContainerText
        bannerBottomText
      }
      ... on ContentfulCaseStudyHero {
        internal {
          type
        }
        richText {
          raw
        }
        orangeBackgroundRectangle{
          title
          gatsbyImageData(
            quality: 100,
            placeholder: BLURRED
          )
        }
        heroCardDesktopImage {
          title
          url
          gatsbyImageData(
            quality: 100,
            placeholder: BLURRED
          )
        }
        heroCardTabletImage {
          title
          url
          gatsbyImageData(
            quality: 100,
            placeholder: BLURRED
          )
        }
        heroCardMobileImage {
          title
          url
          gatsbyImageData(
            quality: 100,
            placeholder: BLURRED
          )
        }
        heroCardLogoImage {
          title
          url
          gatsbyImageData(
            quality: 100,
            placeholder: BLURRED
          )
        }
        clientName
      }
      ... on ContentfulCaseStudyImages {
        internal {
          type
        }
        imageBackground {
          title
          url
          gatsbyImageData(
            quality: 100,
            placeholder: BLURRED
          )
        }
        imageGallery {
          title
          url
          gatsbyImageData(
            quality: 100,
            placeholder: BLURRED
          )
        }
        addBorder
        addPadding
        maxWidth
      }
      ... on ContentfulCaseStudyText {
        internal {
          type
        }
        richText {
          raw
        }
        isLastComponent
        textAlignment
      }
      ... on ContentfulCaseStudyRevenueTable {
        internal {
          type
        }
        beforeYear(formatString: "YYYY")
        beforePaidAdSpend
        beforePaidAdRevenue
        beforeRoas
        beforePaidAdRevenueShare
        afterYear(formatString: "YYYY")
        afterPaidAdSpend
        afterPaidAdRevenue
        afterRoas
        afterPaidAdRevenueShare
      }
      ... on ContentfulCaseStudyLevelCard {
        internal {
          type
        }
        levelCardText {
          raw
        }
      }
      ... on ContentfulCaseStudyBeforeAfterRevenueCard {
        internal {
          type
        }
        beforeFbRevenueStartDate(formatString: "MMM YYYY")
        beforeFbRevenueEndDate(formatString: "MMM YYYY")
        beforeRevenueAmount
        beforeRoas
        afterFbRevenueStartDate(formatString: "MMM YYYY")
        afterFbRevenueEndDate(formatString: "MMM YYYY")
        afterRevenueAmount
        afterRoas
      }
    }
  }
}
`;