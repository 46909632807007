import React from "react";
import styled from "styled-components";

const RevenueTableWrapper = styled.div`
  max-width: 1440px;
  background-color: var(--orange);
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;

  .white_line{
    width: 100%;
    border-top: 7px solid #ffffff;
    height: 1px;
    position: absolute;
    left: 0;
  }

  table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 3rem;
  }

  th{
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 130.4%;
    color: #ffffff;
    text-align: center;
    letter-spacing: -0.005em;
    position: relative;
    padding: 0 3rem;

    @media only screen and (max-width: 1250px){
      font-size: 30px;
      padding: 0 2.5rem;
    }

    @media only screen and (max-width: 1024px){
      font-size: 25px;
      padding: 0 1rem;
    }

    @media only screen and (max-width: 768px){
      font-size: 25px;
      padding: 0 1.5rem;
    }
  }

  td{
    background-color: var(--blue);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 130.4%;
    color: #ffffff;
    text-align: center;
    letter-spacing: -0.005em;

    @media only screen and (max-width: 1250px){
      font-size: 30px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 25px;
    }

    @media only screen and (max-width: 768px){
      font-size: 20px;
      padding: 1rem;
    }
  }

  @media only screen and (max-width: 768px){
    display: none;
  }

  // @media only screen and (min-width: 1920px){
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
`;

const RevenueTable = ({ data }) => {
  const {
    afterPaidAdRevenue,
    afterPaidAdRevenueShare,
    afterPaidAdSpend,
    afterRoas,
    afterYear,
    beforePaidAdRevenue,
    beforePaidAdRevenueShare,
    beforePaidAdSpend,
    beforeRoas,
    beforeYear,
  } = data;
  return (
    <RevenueTableWrapper>
      <table>
        <tr>
          <th>Year</th>
          <th>Paid Ad Spend</th>
          <th>Paid Ad Revenue</th>
          <th>ROAS</th>
          <th>Paid Ad Revenue Share</th>
        </tr>
        <div className="white_line"></div>
        <tr>
          <td>{beforeYear}</td>
          <td>{beforePaidAdSpend}</td>
          <td>{beforePaidAdRevenue}</td>
          <td>{beforeRoas}</td>
          <td>{beforePaidAdRevenueShare}</td>
        </tr>
        <tr>
          <td>{afterYear}</td>
          <td>{afterPaidAdSpend}</td>
          <td>{afterPaidAdRevenue}</td>
          <td>{afterRoas}</td>
          <td>{afterPaidAdRevenueShare}</td>
        </tr>
      </table>
    </RevenueTableWrapper>
  );
};

export default RevenueTable;