import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CSCWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 10%;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  .card{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: 337px;
    box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.25);
  }

  .card--desktop{
    max-height: 816px;
    max-width: 378px;
    height: 816px;

    .top_image__container{
      height: 75%;
    }

    .bot_image__container{
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
    }

    @media only screen and (max-width: 768px){
      display: none;
    }
  }

  .top_image{
    height: 100%;
  }

  .bot_image{
    /* padding: 1rem; */
  }

  .card--tablet{
    max-height: 501px;
    max-width: 500px;
    height: 400px;
    position: relative;
    z-index: 5;

    .top_image__container{
      width: 100%;
      height: 60%;
    }

    .bot_image__container{
      width: 100%;
      height: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /* @media only screen and (min-width: 769px){
      display: none;
    } */

    @media only screen and (max-width: 768px){
      height: 501px;
    }

    @media only screen and (max-width: 500px){
      display: none;
    }
  }

  .card--mobile{
    max-height: 337px;
    max-width: 283px;
    height: 337px;
    

    .top_image__container{
      height: 50%;
    }

    .bot_image__container{
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /* @media only screen and (min-width: 501px) and (max-width: 768px){
      display: none;
    } */

    @media only screen and (min-width: 501px){
      display: none;
    }
  }

  @media only screen and (max-width: 768px){
    position: static;
    transform: translate(0,0);
  }

`;

const CaseStudyCard = ({ images }) => {
  const { heroCardDesktopImage, heroCardTabletImage, heroCardMobileImage, heroCardLogoImage } = images;
  return (
    <CSCWrapper>
      {/* {heroCardDesktopImage && <div className="card card--tablet">
        <div className="top_image__container">
          <GatsbyImage alt={heroCardTabletImage.title} image={getImage(heroCardTabletImage.gatsbyImageData)}/>
        </div>
        <div className="bot_image__container">
          <GatsbyImage alt={heroCardLogoImage.title} image={getImage(heroCardLogoImage.gatsbyImageData)}/>
        </div>
      </div>} */}
      {heroCardTabletImage && <div className="card card--tablet">
        <div className="top_image__container">
          <GatsbyImage alt={heroCardTabletImage.title} className="top_image" image={getImage(heroCardTabletImage.gatsbyImageData)}/>
        </div>
        <div className="bot_image__container">
          <GatsbyImage alt={heroCardLogoImage.title} className="bot_image" image={getImage(heroCardLogoImage.gatsbyImageData)}/>
        </div>
      </div>}
      {heroCardMobileImage && <div className="card card--mobile">
        <div className="top_image__container">
          <GatsbyImage alt={heroCardMobileImage.title} image={getImage(heroCardMobileImage.gatsbyImageData)}/>
        </div>
        <div className="bot_image__container">
          <GatsbyImage alt={heroCardLogoImage.title} image={getImage(heroCardLogoImage.gatsbyImageData)}/>
        </div>
      </div>}
    </CSCWrapper>
  );
};

export default CaseStudyCard;